var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item equipment-item",on:{"click":function($event){return _vm.pick(_vm.equipment.id)}}},[_c('div',{staticClass:"equipment-icon",class:{
      'btn-success': _vm.equipment.is_connected && !_vm.equipment.has_active_alarms,
      'btn-warning': !_vm.equipment.is_connected && !_vm.equipment.has_active_alarms,
      'btn-danger': _vm.equipment.has_active_alarms
    }},[_c('i',{staticClass:"fa",class:{
        'fa fa-exclamation-triangle':
          _vm.equipment.has_active_alarms || !_vm.equipment.is_connected,
        'fa-check': !_vm.equipment.has_active_alarms
      }})]),_c('div',{staticClass:"product-info"},[_c('span',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.equipment.name.toUpperCase())+" - "+_vm._s(_vm.equipment.modelo))]),_c('span',{staticClass:"product-description"},[_vm._v(_vm._s(_vm.equipment.modelo)+" - "+_vm._s(_vm.equipment.numero_serie))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }